<template>
  <div class="doc-detail-page content-block">
    <div class="doc-form">
      <div class="left">
        <fm-title title-text="公文详细"></fm-title>
        <div class="table-div">
          <table border="0" cellspacing="0" cellpadding="0" class="doc-detail-table">
          <tbody>
            <tr>
              <td style="min-width: 60px;">收文编码</td>
              <td>{{docData.getCode}}</td>
              <td>收文日期</td>
              <td class="right-td">{{docData.getTime ? docData.getTime.slice(0, 10) : ''}}</td>
            </tr>
            <tr>
              <td>来文单位</td>
              <td>{{docData.sendOrgName}}</td>
              <td>来文号</td>
              <td class="right-td">{{docData.sendCode}}</td>
            </tr>
            <tr>
              <td>标题</td>
              <td class="right-td" colspan="3">{{docData.title}}</td>
            </tr>
            <tr>
              <td>份数</td>
              <td>{{docData.num}}</td>
              <td>来文日期</td>
              <td class="right-td">{{docData.sendTime ? docData.sendTime.slice(0, 10) : ''}}</td>
            </tr>
            <tr v-for="(step, index) in stepList" :key="step.id">
              <td :class="{'bottom-td': index === stepList.length - 1}">{{step.type}}</td>
              <td class="right-td" :class="{'bottom-td': index === stepList.length - 1}" colspan="3">
                <div v-html="getHtml(step.handles || [])" style="min-height: 70px;"></div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
      <div class="right">
        <div>
          <fm-title title-text="文件"></fm-title>
        <file-manage
          @uploadIng="uploadIng"
          preview
          :file-data-ids="fileIds"
          @addFile="addFile"
          @delFile="delFile"
          :funs="{get: true, upload: false, del: false}">
        </file-manage>
        </div>
        <div style="margin-bottom: 20px;margin-right: 2rem;" v-if="docData.status === 'doing' && handleList && handleList.length > 0">
          <fm-title title-text="编辑内容"></fm-title>
        <div class="fm-tabs fm-tabs-norm" style="margin-bottom: 10px;">
          <div class="fm-tabs-nav">
            <div @click="chooseHandle = item" class="fm-tabs-nav-item" :class="{'fm-tabs-nav-active': item.id === (chooseHandle ? chooseHandle.id : null)}" v-for="item in handleList" :key="item.id">
              {{item.officialDocumentHandleStepType}}
            </div>
          </div>
          </div>
          <fm-input-new v-if="chooseHandle" v-model="chooseHandle.content" type="textarea" placeholder="" style="width: 90%;"/>
          <div v-if="chooseHandle" class="ps-div">
            <fm-btn @click="submit">提交</fm-btn>
            <fm-btn @click="modal = true">安排人员</fm-btn>
          </div>
        </div>
      </div>
    </div>
    <fm-modal v-model="modal" v-if="modal" width="800px">
      <step-form @setpChange="setpChange" :workerList="$store.getters.allWorkerList" :stepList="stepList"></step-form>
      <div class="fm-footer">
      <fm-btn style="margin-right: 5rem;" @click="save">确定</fm-btn>
      <fm-btn @click="modal = false">取消</fm-btn>
    </div>
    </fm-modal>
  </div>
</template>

<script>
import FileManage from '@/components/base/FileManage'
import StepForm from './cmp/stepForm'

import {
  officialDocumentRequest,
  officialDocumentHandleRequest
} from '@/api'

export default {
  components: {
    FileManage,
    StepForm
  },
  data () {
    return {
      isHandle: false,
      chooseHandle: null,
      handleList: [],
      modal: false,
      id: null,
      loading: false,
      docData: {},
      fileIds: [],
      stepList: [],
      newStepList: null
    }
  },
  activated () {
    this.handleList = []
    this.chooseHandle = null
    this.fileIds = []
    this.stepList = []
    this.docData = {}
    this.id = this.$router.history.current.query.id
    this.isHandle = Number(this.$router.history.current.query.handle) === 1
    this.loadData()
    this.loadMy()
  },
  created () {
    this.$store.dispatch('loadAllWorkerList')
  },
  methods: {
    getHtml (handles) {
      let text = ''
      handles.filter(v => v.status === 'over').forEach(v => {
        text += (v.content ? (v.content + ' ') : '') + v.workerName + ' ' + (v.handleTime ? v.handleTime.slice(0, 10) : '') + '<br>'
      })
      return text
    },
    async submit () {
      await officialDocumentHandleRequest.update(this.chooseHandle.id, this.chooseHandle)
      this.loadMy()
      this.loadData()
    },
    async loadMy () {
      if (this.id && this.isHandle) {
        let datas = await officialDocumentHandleRequest.getMy({
          officialDocumentId: this.id
        })
        this.handleList = datas || []
        if (this.handleList.length > 0) {
          if (this.chooseHandle) {
            this.chooseHandle = this.handleList.find(v => v.id === this.chooseHandle.id)
          } else {
            this.chooseHandle = this.handleList[0]
          }
        }
      }
    },
    async loadData () {
      if (this.id) {
        let datas = await officialDocumentRequest.getDetail({
          officialDocumentId: this.id
        })
        if (datas.length > 0) {
          this.docData = datas[0]
          this.fileIds = this.docData.fileIds ? this.docData.fileIds.split(',') : []
          this.stepList = this.docData.steps || []
        }
      }
    },
    setpChange (data) {
      this.newStepList = data
    },
    uploadIng (uploadIng) {
      this.loading = uploadIng
    },
    addFile (data) {
      this.fileIds.push(data.id)
    },
    delFile (data) {
      this.fileIds = this.fileIds.filter(v => Number(v) !== data.id)
    },
    async save () {
      let parm = Object.assign({}, this.docData, {
        fileIds: this.fileIds.length > 0 ? this.fileIds.join(',') : null,
        sendTime: this.docData.sendTime && this.docData.sendTime instanceof Date ? this.$datetime.format(this.docData.sendTime, 'Y-M-D') : this.docData.sendTime,
        stepList: this.newStepList
      })
      await officialDocumentRequest.add(parm)
      this.modal = false
      this.loadData()
      this.loadMy()
    }
  }
}
</script>

<style lang="less" scoped>
.table-div {
  width: 100%;
  height: calc(100% - 48px);
  overflow-y: auto;
}
.doc-detail-page {
  margin: 20px;
  height: calc(100% - 40px);
}
.ps-div {
  margin-top: 20px;
  display: flex;
  margin-right: 10%;
  justify-content: flex-end;
}
.doc-detail-table {
  width: calc(100% - 20px);
  padding-left: 20px;
  td {
    font-size: 14px;
    padding: 0 8px;
    line-height: 30px;
    border-top: 1.5px solid #F4628F;
    border-left: 1.5px solid #F4628F;
  }
  .right-td {
    border-right: 1.5px solid #F4628F;
  }
  .bottom-td {
    border-bottom: 1.5px solid #F4628F;
  }
}
.doc-form {
  display: flex;
  width: 100%;
  height: 100%;
}
.left {
  flex: 1;
  margin-right: 2rem;
}
.right {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>